import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t } = useTranslation('termsAndConditionsPage');
  return (
    <>
      <div className="container mx-auto lg:px-[20px] px-[16px] lg:py-[80px] py-[60px] max-w-[56em]">
        <h1 className="font-bold text-[48px] mb-[50px] text-black text-center">
          {t('TermsAndConditions.Title')}
        </h1>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <p>
            {t('TermsAndConditions.Paragraph1')}
          </p>
          <p>{t('TermsAndConditions.Span1')}</p>
          <p>{t('TermsAndConditions.Span2')}</p>
          <p>{t('TermsAndConditions.Span3')}</p>
          <p>{t('TermsAndConditions.Span4')}</p>
          <p>
            <p>{t('TermsAndConditions.Address')}</p>
          </p>
          <p>{t('TermsAndConditions.Phone')}</p>
          <p>E-mail: info@tfcapital.me</p>
        </div>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <h2 className="text-[24px] font-bold text-black mb-[20px]">
            {t('TermsAndConditions.Title2')}
          </h2>
          <p>
            {t('TermsAndConditions.Paragraph2')}
          </p>
        </div>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <h2 className="text-[24px] font-bold text-black mb-[20px]">
            {t('TermsAndConditions.Title3')}
          </h2>
          <p>
            {t('TermsAndConditions.Paragraph3')}
          </p>
        </div>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <h2 className="text-[24px] font-bold text-black mb-[20px]">
            {t('TermsAndConditions.Title4')}
          </h2>
          <p>
            {t('TermsAndConditions.Paragraph4')}
          </p>
        </div>
        <div className="text-[#585768] text-[16px] leading-[28px] mb-[40px]">
          <h2 className="text-[24px] font-bold text-black mb-[20px]">
            {t('TermsAndConditions.Title5')}
          </h2>
          <p>
            {t('TermsAndConditions.Paragraph5')}

          </p>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
